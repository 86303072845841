/*
 * Globals
 */

/* Custom default button */
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
	color: #333;
	text-shadow: none; /* Prevent inheritance from `body` */
	background-color: #fff;
	border: 0.05rem solid #fff;
}

$backgroundColor: #333;
/*
 * Base structure
 */

html,
body {
	height: 100%;
	background-color: backgroundColor;
}

$blurSize: 5px;
$backImage: "./images/back01";
//$backImage: "./images/back02";
//$backImage: "./images/back03";
//$backImage: "./images/back04";

.fullImage {
	filter: blur($blurSize);
	-webkit-filter: blur($blurSize);

	background: url($backImage+"_1920.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.shadowBox {
	background-color: rgba(0, 0, 0, 0.65);
	box-shadow: inset 0 0 5rem rgba(0, 0, 0, 0.5);
}

.shadowText {
	color: #fff;
	text-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.5);
}

.max-center-box-width {
	max-width: 42em;
	overflow-y: auto;
}

/*
 * Header
 */
.masthead {
	margin-bottom: 2rem;
}

.masthead-brand {
	margin-bottom: 0;
}

.nav-masthead .nav-link {
	padding: 0.25rem 0;
	font-weight: 700;
	color: rgba(255, 255, 255, 0.5);
	background-color: transparent;
	border-bottom: 0.25rem solid transparent;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
	border-bottom-color: rgba(255, 255, 255, 0.25);
}

.nav-masthead .nav-link + .nav-link {
	margin-left: 1rem;
}

.nav-masthead .active {
	color: #fff;
	border-bottom-color: #fff;
}

@media (min-width: 48em) {
	.masthead-brand {
		float: left;
	}
	.nav-masthead {
		float: right;
	}
}

/*
 * Cover
 */
.cover {
	padding: 0 1.5rem;
}
.cover .btn-lg {
	padding: 0.75rem 1.25rem;
	font-weight: 700;
}

/*
 * Footer
 */
.mastfoot {
	color: rgba(255, 255, 255, 0.5);
}

//-- Removes little up down arrows https://stackoverflow.com/a/49245702
.custom-select {
	background: white !important;
}

//-- Hiding Input Arrows for Number TextBoxes

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type="number"] {
	-moz-appearance: textfield;
}

/*--- REmove Bootstrap HIghlight on TB */
textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active,
.form-control:focus {
	outline: 0px !important;
	-webkit-appearance: none;
	box-shadow: none !important;
	border-color: rgb(206, 212, 218);
}
