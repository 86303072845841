$primary: #f68e2d;
$secondary: #cccccc;
$success: #758e4f;
$info: #33658a;
$warning: #fbb13c;
$danger: #992626;
$light: #f4f4f4;

$body-color: #ffffff !default;

//-- Printing
$print-page-size: auto;

//-- Enable Responsive Text
$enable-responsive-font-sizes: true;
$print-body-min-width: 995px;
/* Legacy

*/
/*$success: #6DF285;
$info: #20B6F5;
$warning: #F4B800;
$danger: #F26C6B;*/
//$card-cap-bg: #333333;
//$light: $gray-100 !default;
//$dark: #232323 !default;
//-- For color button
// The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
$yiq-contrasted-threshold: 163 !default;
/*$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800
) !default;*/

$grid-breakpoints: (
	xs: 0px,
	ip5: 321px,
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px
) !default;

/*// Slate 4.1.3
// Bootswatch

//
// Color system
//

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #919191 !default;
$gray-600: #7A8288 !default;
$gray-700: #3F3F3F !default;
$gray-800: #333333 !default;
$gray-900: #232323 !default;
$black: #000 !default;



$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #ee5f5b !default;
$orange: #fd7e14 !default;
$yellow: #f89406 !default;
$green: #62c462 !default;
$teal: #20c997 !default;
$cyan: #5bc0de !default;

$light: #919191 !default;

// Body

$body-bg: $gray-900 !default;
$body-color: #aaa !default;

// Links

$link-color: $white !default;

// Fonts

$font-size-base: 0.9375rem !default;

// Tables

$table-accent-bg: rgba($white,.05) !default;
$table-hover-bg: rgba($white,.075) !default;

$table-border-color: rgba($black,.6) !default;

$table-dark-border-color: $table-border-color !default;
$table-dark-color: $white !default;

// Buttons

$input-btn-padding-y: .75rem !default;
$input-btn-padding-x: 1rem !default;

// Forms

$input-disabled-bg: #ccc !default;

// Dropdowns

$dropdown-bg: $gray-800 !default;
$dropdown-divider-bg: rgba($black,.15) !default;

$dropdown-link-color: $body-color !default;
$dropdown-link-hover-color: $white !default;
$dropdown-link-hover-bg: $body-bg !default;

// Navs

$nav-tabs-border-color: rgba($black, 0.6) !default;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $white !default;
$nav-tabs-link-active-border-color: $nav-tabs-border-color !default;

// Navbar

$navbar-padding-y: 0 !default;

$navbar-dark-hover-color: $white !default;

$navbar-light-hover-color: $gray-800 !default;
$navbar-light-active-color: $gray-800 !default;


// Pagination

$pagination-color: $white !default;
$pagination-bg: transparent !default;
$pagination-border-color: rgba($black, 0.6) !default;

$pagination-hover-color: $white !default;
$pagination-hover-bg: transparent !default;
$pagination-hover-border-color: rgba($black, 0.6) !default;

$pagination-active-bg: transparent !default;
$pagination-active-border-color: rgba($black, 0.6) !default;

$pagination-disabled-bg: transparent !default;
$pagination-disabled-border-color: rgba($black, 0.6) !default;


// Jumbotron

$jumbotron-bg: darken($gray-900, 5%) !default;

// Cards

$card-border-color: rgba($primary, 0.6) !default;
$card-cap-bg: lighten($gray-800, 10%) !default;
$card-bg: lighten($body-bg, 5%) !default;

// Popovers

$popover-bg: lighten($body-bg, 5%) !default;

// Modals

$modal-content-bg: lighten($body-bg, 5%) !default;

$modal-header-border-color: rgba(0,0,0,.2) !default;

// Progress bars

$progress-bg: darken($gray-900, 5%) !default;
$progress-bar-color: $gray-600 !default;

// List group

$list-group-bg: lighten($body-bg, 5%) !default;
$list-group-border-color: rgba($black, 0.6) !default;

$list-group-hover-bg: lighten($body-bg, 10%) !default;
$list-group-active-color: $white !default;
$list-group-active-bg: $list-group-hover-bg !default;
$list-group-active-border-color: $list-group-border-color !default;

$list-group-disabled-color: $gray-700 !default;

$list-group-action-color: $white !default;

// Breadcrumbs

$breadcrumb-active-color: $gray-500 !default;

// Code

$pre-color: inherit !default;*/
