
$zoomDuration: 300ms;

.zoom-enter {
    opacity: 0;
    transform: scale(0.9);
}
.zoom-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity $zoomDuration, transform $zoomDuration;
}
.zoom-exit {
    opacity: 1;
}
.zoom-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity $zoomDuration, transform $zoomDuration;
}
  