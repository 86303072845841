@import "./styles/bootstrap_overrides.scss";
@import "~bootstrap/scss/bootstrap";
@import "./styles/transitions.scss";

#root {
	width: 100%;
}

::-webkit-scrollbar {
	width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: $primary;
	border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: lighten($primary, 5%);
}

.transitionEase {
	transition: all 0.3s;
	transition-property: all;
	transition-duration: 0.3s;
	transition-timing-function: ease;
	transition-delay: 0s;
}

.listingResult {
	margin-bottom: 12px;

	.listing-thumb {
		overflow: hidden;

		img {
			@extend .transitionEase;
			object-fit: contain;
			max-height: 200px;
			width: 100%;
			z-index: 0;
			overflow: hidden;
			position: relative;

			&:hover {
				transform: scale(1.1);
			}
		}
	}

	.card-body {
		z-index: 1;
	}
}

.react-select-container {
	color: black;
	.react-select__control {
		outline-color: transparent !important;

		&.react-select__control--is-focused {
			border-color: $primary !important;
			box-shadow: 0 0 0 1px $primary !important;
			outline-color: $primary !important;
		}
	}

	.react-select__value-container {
		&.react-select__value-container--has-value {
			&:focus {
				outline-color: $primary !important;
			}
			.react-select__single-value {
				@extend .px-2;
				@extend .px-lg-3;
				@extend .py-0;
				@extend .btn;
				@extend .btn-primary;
			}
		}
	}

	.react-select__menu {
		z-index: 22;

		.react-select__menu-list {
			.react-select__option {
				&:hover {
					background-color: lighten($primary, 10);
					color: $white;
				}
				color: "black" !important;
			}
			// .react-select__option--is-disabled {

			// }
			// .react-select__option--is-focused {

			// }
			.react-select__option--is-selected {
				background-color: $primary;
			}
		}
	}
}
